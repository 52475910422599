import img1 from '../../images/portfolioImages/KniagychyHouse.jpg';
// import img1 from '../../images/portfolioImages/Кotteg-Knajychy.jpg';
import img2 from '../../images/portfolioImages/parking-400-places-Lviv.jpg';
import img3 from '../../images/portfolioImages/Living-House in Lviv.jpg';
import img4 from '../../images/portfolioImages/TRC Chervona Ruta2.jpg';
import img5 from '../../images/portfolioImages/DatactnterOdessa.jpg';
import img6 from '../../images/portfolioImages/DAF Service Station in Solonka3.jpg';
import img7 from '../../images/portfolioImages/Eurohotel reconstruction in Lviv.jpg';
import img8 from '../../images/portfolioImages/cableway in Tysovetch.jpg';
import img9 from '../../images/portfolioImages/House in Pidbirtci.jpg';
import img10 from '../../images/portfolioImages/Health-improving Proposal in Shidnyca.jpg';
import img11 from '../../images/portfolioImages/GorganyForSite_cor.jpg';
import img12 from '../../images/portfolioImages/VBForSite_cor.jpg';


const portfolioImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
];

export default portfolioImages;

