const benefits = {
  benefitsTitle: 'Чому саме ми',
  subtitle1:
    'Ми вирішуємо всі питання з розробки проектної документації від обстеження об’єкту нерухоимості до завершення будівництва, а саме:',

  items1: [
    '●	Проектні роботи по всім  розділам архітектурно-будівельного комплексу',
    '●	Розробку проектно-кошторисної документації від передпроектних робіт до авторського нагляду',
    '●	Обстеження будівель та споруд, посилення будівельних конструкцій',
    '●	Погодження проектної документації',
    '●	Здійснюємо допомогу в оформленні дозволу на будівництво',
    '●	Девелоперську діяльність у сфері будівництва.',
    '●	Функції генпідряду у сфері будівництва',
    '●	Здачу об’єктів в експлуатацію та отримання сертифіката відповідності об’єкта',
    '●	Консалтинг в сфері будівництва',
  ],
  subtitle2: 'Сфера нашої діяльності:',
  items2: [
    '●	Обстеження будівель та споруд і оцінка технічного стану будівельних конструкцій та інженерних мереж',
    '●	Консультаційні послуги в галузі будівництва  ',
  ],
};
export default benefits;
