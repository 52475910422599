const team = {
  teamTitle: 'Про нас',
  subhead: '',
  companyName: 'Ретро Проект',
  part1:
    '- це творча команда професіоналів, яка добре знає свою справу, та вже встигла зарекомендувати себе на теренах України як сучасна та перспективна консалтингова проектно-будівельна фірма ',
  part2:
    'Фірма має у своєму штаті висококваліфікованих спеціалістів із значним досвідом проектування громадських, цивільних, промислових, сакральних будівель та споруд, інженерно-технічний персонал та робітників необхідних професій та кваліфікацій, які мають знання та досвід роботи у галузі будівництва',
  subtitle2: 'Структура фірми',
  directionsListTitle: 'Фірма має у своєму складі проектні підрозділи:',
  directionsList: [
    {
      title: 'Архітектурний',
      content:
        ' - розробка ГП (генерального плану) і АР (архітектурних рішень)',
    },
    {
      title: 'Будівельно-конструкторський',
      content:
        " - розробка конструкторських розрахунків та креслень по розділах: КБ (конструкції залізобетонні), КМ (конструкції металеві), КД (конструкції дерев'яні",
    },
    {
      title: 'Санітарно-технічний',
      content:
        ' - розробка технічних розрахунків та рішень по розділам проекту:  ОВ (опалення, вентиляція та кондиціювання), ВК (водопровід та каналізація), ТС (теплопостачання і газопостачання)',
    },
    {
      title: 'Електротехнічний',
      content:
        ' - розробка документації по розділах: ЕП/ЕО (електропостачання, електричне освітлення), АТ (автоматизація), СЗ (системи зв’язку) ',
    },
    {
      title: 'Кошторисний',
      content: ' - розробка кошторисної документації (К)',
    },
  ],
  additionalDirections: {
    comment: 'A також ',
    title: ' Відділ девелоперського розвитку та будівництва',
  },
  specialDirectionsTitle:
    'Також ТОВ "Ретро Проект" розробляє спеціалізовані розділи проектування: ',
  specialDirection: [
    '•	технологічний розділ ',
    ' •	обстеження і оцінка технічного стану будівель та споруд ',
    '•	оцінка впливу на навколишнє середовище',
    '•	проектування організації будівництва',
    '•	охорона праці',
    '•	захист від впливу шуму',
    '•	консультації в сфері будівництва / Розробка рекомендацій з беспечної експлуатації будівель та споруд',
  ],
};
export default team;
