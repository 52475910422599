const benefits = {
  benefitsTitle: 'Why us',
  subtitle1:
    'We solve all issues related to the development of project documentation from the inspection of the real estate object to the completion of construction, namely:',
  items1: [
    '● Project work on all sections of the architectural and construction complex',
    "● Development of design and estimate documentation from pre-project works to author's supervision",
    '● Inspection of buildings and structures, strengthening of building structures',
    '● Approval of project documentation',
    '● We provide assistance in obtaining a construction permit',
    '● Development activity in the field of construction.',
    '● Functions of general contracting in the field of construction',
    '● Putting objects into operation and obtaining a certificate of conformity of the object',
    '● Consulting in the field of construction',
  ],
  subtitle2: 'Our field of activity:',
  items2: [
    '● Inspection of buildings and structures and assessment of the technical condition of building structures and engineering networks',
    '● Consulting services in the field of construction',
  ],
};
export default benefits;
