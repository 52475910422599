import portfolioImages from 'components/Portfolio/portfolioContent';

const [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12] =
  portfolioImages;

const portfolio = {
  title: 'Objects we made',
  portfolioContent: [
    {
      title: 'Private cottage in the village of Knyazhychi, Kyiv region',
      img: img1,
      id: 'img1',
      alt: 'house in Kniagychi',
    },
    {
      title: '400 parking lot in Lviv',
      img: img2,
      id: 'img2',
      alt: 'parking for 400 autos in Lviv',
    },
    {
      title: 'High-rise residential building in Lviv',
      img: img3,
      id: 'img3',
      alt: 'apartments house in Lviv',
    },
    {
      title:
        'Reconstruction of non-residential premises for the Chervona Ruta restaurant at 28 Khutorivka St. in Lviv',
      img: img4,
      id: 'img4',
      alt: 'Chervona Ruta',
    },
    {
      title:
        'Reconstruction of non-residential premises sub-administrative and technical complex of Kyivstar Private Joint Stock Company in Odesa',
      img: img5,
      id: 'img5',
      alt: 'Data-center Kyivstar in Odesa',
    },
    {
      title:
        'Truck parking lot, service station, office and warehouse premises in Solonka in Lviv region',
      img: img6,
      id: 'img6',
      alt: 'Data-center Kyivstar in Odesa',
    },
    {
      title:
        'Reconstruction of "Eurohotel" including the extension of the floor on the street. Tershakovtsiv, 6a in Lviv',
      img: img7,
      id: 'img7',
      alt: 'Eurohotel reconstruction in Lviv',
    },
    {
      title:
        'Cable-chair road in the village of Tysovets, Skoliv district, Lviv region',
      img: img8,
      id: 'img8',
      alt: 'cableway in Tysovetch',
    },
    {
      title: 'Cottage in the village Pidbirtci, Lviv region',
      img: img9,
      id: 'img9',
      alt: 'House in Pidbirtci',
    },
    {
      title:
        'Project proposal for the construction of a medical and recreational complex for 180 places in the city of Skhidnytsia',
      img: img10,
      id: 'img10',
      alt: 'Therapeutic and health-improving for 180 places Proposal in Shidnyca',
    },
    {
      title:
          'Reconstruction of the building for the administrative center of the nature reserve "Gorgany" in the city of Nadvirna, Ivano-Frankivsk region',
      img: img11,
      id: 'img11',
      alt: 'building for the administrative center of the nature reserve "Gorgany"',
    },
    {
      title:
          'Reconstruction of the unfinished construction for the visit center of the Uzhan National Park in the village of Velyky Berezny, Transcarpathian region',
      img: img12,
      id: 'img12',
      alt: 'visit center of the Uzhan National Park in the village of Velyky Berezny"',
    },
  ],
};
export default portfolio;
